/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Select from "react-select";
import ReactQuill from "react-quill";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import DateTime from "react-datetime";
import "emoji-slider";

/* MATERIAL TAILWIND COMPONETS */
import {
  Button,
  Card,
  CardBody,
  Dialog,
  DialogBody,
  Spinner,
} from "@material-tailwind/react";

/* TRADING VIEW CHART */
import TradingViewWidget from "../../../components/trading-view/chart";
import TradingViewWidgetDark from "../../../components/trading-view/chart-dark";
import {
  NewTradingViewDark,
  NewTradingViewLight,
} from "../../../components/new-trading-view/dark";

/* REDUX IMPORTS */
import { useDispatch, useSelector } from "react-redux";
import {
  addNotebook,
  closeTrade,
  getNotebook,
  getTag,
  getTradeById,
  getTradeViewList,
  updateNotebook,
  updateTrade,
  uploadAttByTrade,
} from "../../../service/api";

/* ICONS */
import { FaFileUpload, FaFile } from "react-icons/fa";
import moment from "moment";
import actions from "../../../redux/user/actions";
import { IconMoodCrazyHappy, IconMoodSadFilled, IconMoodSmileDizzy, IconMoodSmileFilled, IconMoodSpark } from "@tabler/icons-react";

const TradeReplay = () => {
  const [noteDialog, setNoteDialog] = useState(false);
  const { selectedTrade } = useSelector((state) => state.user);
  const [data, setData] = useState();
  const [noteData, setNoteData] = useState();
  const [tagData, setTagData] = useState();
  const [mistakeData, setMistakeData] = useState();
  const [tag, setTag] = useState();
  const [quillValue, setQuillValue] = useState("");
  const [mistake, setMistake] = useState();
  const [statsVariables, setStatsVariables] = useState([]);
  const [stopLoss, setStoploss] = useState();
  const [profit, setProfit] = useState();
  const [loading, setLoading] = useState(false);
  const [graphDataLoader, setGraphDataLoader] = useState(false);
  const [rating, setRating] = useState(0);
  const [squareOffDialog, setSquareOffDialog] = useState(false);
  const dispatch = useDispatch();
  /* STATISTICS TILE */
  const StatisticsTile = (props) => {
    return (
      <div className="w-full flex items-center justify-between rounded px-5 py-3 even:bg-white dark:even:bg-darkSidebar odd:bg-[#F5F5F5] dark:odd:bg-bgDark">
        <p className="text-sm font-proxima-semibold text-[#777] dark:text-lightGreyText leading-none">
          {props.title}
        </p>
        <p className="text-[1.6vh] font-proxima-bold text-black dark:text-white leading-none">
          {props.value}
        </p>
      </div>
    );
  };
  const validation = (currentDate) => {
    return currentDate.isBefore(new Date());
  };
  function calculatePlannedRiskRewardRatio(
    plannedProfitTarget,
    plannedStopLoss
  ) {
    const potentialProfit = plannedProfitTarget;
    const potentialLoss = plannedStopLoss;

    if (potentialLoss <= 0) {
      console.error(
        "Error: Planned stop-loss should be below planned profit target."
      );
      return null;
    }

    const riskRewardRatio = potentialProfit / potentialLoss;

    return riskRewardRatio?.toFixed(2);
  }

  function calculateRealizedRiskRewardRatio(
    entryPrice,
    exitPrice,
    stopLossPrice,
    side
  ) {
    const distanceToStopLoss = Math.abs(entryPrice - stopLossPrice);
    const realizedProfitLoss = Math.abs(exitPrice - entryPrice);
    const riskRewardRatio = `${(
      realizedProfitLoss / distanceToStopLoss
    ).toFixed(1)} : ${(distanceToStopLoss / distanceToStopLoss).toFixed(1)}`;
    return riskRewardRatio;
  }

  function calculateExpectedRiskRewardRatio(
    entryPrice,
    stopLossPrice,
    takeProfit,
    side
  ) {
    const realizedProfitLoss = Math.abs(takeProfit - entryPrice);
    const distanceToStopLoss = Math.abs(entryPrice - stopLossPrice);
    const riskRewardRatio = `${(
      realizedProfitLoss / distanceToStopLoss
    ).toFixed(1)} : ${(distanceToStopLoss / distanceToStopLoss).toFixed(1)}`;
    return riskRewardRatio;
  }

  function calculateRealizedRMultiple(entryPrice, exitPrice, stopLossPrice) {
    const distanceToStopLoss = entryPrice - stopLossPrice;

    if (distanceToStopLoss <= 0) {
      console.error("Error: Stop-loss price should be below entry price.");
      return null;
    }

    const realizedProfitLoss = exitPrice - entryPrice;
    const initialRisk = distanceToStopLoss;

    const realizedRMultiple = realizedProfitLoss / initialRisk;

    return realizedRMultiple;
  }
  const [tradeData, setTradeData] = useState([]);
  console.log("tradeData: ", tradeData);
  const getTradeData = () => {
    setLoading(true);

    getTradeById(selectedTrade?._id).then((res) => {
      setLoading(false);

      if (res?.status) {
        setData(res?.data);
        // dispatch(actions.setSelectedTrade(res?.data))
        if (res?.data?.profitTarget) {
          setProfit(res?.data?.profitTarget);
        }
        if (res?.data?.rating) {
          const rangeSlider = document.querySelector("#rangeSlider");

          setTimeout(() => {
            if (rangeSlider && rangeSlider?.value == 0 && res?.data?.rating)
              rangeSlider.value = res?.data?.rating / 10;
          }, 100);
        }
        if (res?.data?.stopLoss) {
          setStoploss(res?.data?.stopLoss);
        }

        let stat = [
          {
            id: 1,
            title: "Entry Time",
            value: res?.data?.rdate
              ? moment(res?.data?.rday, "dddd").format("dddd").substring(0, 3) +
                ", " +
                moment(res?.data?.rdate, "DD/MM/YYYY").format("MMM DD YYYY") +
                ", " +
                moment(res?.data?.rtime, "HH:mm:ss").format("hh:mm")
              : "-",
          },
          {
            id: 2,
            title: "Exit Time",
            value:
              res?.data?.date && !res?.data?.isOpen
                ? moment(res?.data?.day, "dddd")
                    .format("dddd")
                    .substring(0, 3) +
                  ", " +
                  moment(res?.data?.date, "DD/MM/YYYY").format("MMM DD YYYY") +
                  ", " +
                  moment(res?.data?.time, "HH:mm:ss").format("hh:mm")
                : "-",
          },
          {
            id: 3,
            title: "Quantity",
            value: res?.data?.buyQuantity,
          },
          {
            id: 4,
            title: "Side",
            value: res?.data?.side,
          },
          {
            id: 5,
            title: "Trade ROI",
            value:
              (
                ((res?.data?.profit ? res?.data?.profit : 0) * 100) /
                (res?.data?.side === "LONG"
                  ? res?.data?.buyAmount
                  : res?.data?.sellAmount)
              )
                .toFixed(2)
                .toLocaleString("en-IN") + "%",
          },
          {
            id: 6,
            title: "Gross P&L",
            value:
              "₹" +
              (res?.data?.profit
                ? res?.data?.profit?.toFixed(2).toLocaleString("en-IN")
                : "0.00"),
          },
          {
            id: 8,
            title: "Profit Target",
            value:
              "₹" +
              (res?.data?.profitTarget
                ? res?.data?.profitTarget?.toFixed(2).toLocaleString("en-IN")
                : "0.00"),
          },
          {
            id: 9,
            title: "Stop Loss",
            value:
              "₹" +
              (res?.data?.stopLoss
                ? res?.data?.stopLoss?.toFixed(2).toLocaleString("en-IN")
                : "0.00"),
          },
          {
            id: 10,
            title: "Entry Price",
            value:
              "₹" +
              (res?.data?.side === "LONG"
                ? res?.data?.buyPrice
                : res?.data?.sellPrice
              ).toFixed(2),
          },
          {
            id: 11,
            title: "Exit Price",
            value:
              "₹" +
              (res?.data?.side === "LONG"
                ? res?.data?.sellPrice
                : res?.data?.buyPrice
              )?.toFixed(2),
          },

          {
            id: 12,
            title: "Realized Risk Reward Ratio",
            value: res?.data?.realizeRisk ? res?.data?.realizeRisk : "-",
          },
          {
            id: 13,
            title: "Expected Risk Reward Ratio",
            value: res?.data?.expectedRisk ? res?.data?.expectedRisk : "-",
          },
        ];
        if (res?.data?.profitTarget && res?.data?.stopLoss) {
        }
        setStatsVariables(stat);
      }
    });
    // Replace selectedTrade?._id
    setGraphDataLoader(true);
    getTradeViewList(selectedTrade?._id)
      .then((res) => {
        setGraphDataLoader(false);

        console.log("res: ", res);
        if (res?.status) {
          let arr2 = [];
          let arr = {
            close: [],
            high: [],
            low: [],
            open: [],
            time: [],
            volume: [],
          };
          let arr3 = [];
          res?.data?.map((obj) => {
            // arr.time.push(new Date(obj[0]).getTime()/1000)
            // arr.close.push(obj[4])
            // arr.high.push(obj[2])
            // arr.low.push(obj[3])
            // arr.open.push(obj[1])
            // arr.volume.push(obj[5])
            // arr3.push({
            //   time: new Date(obj[0]).getTime()/1000, open: obj[1], high: obj[2], low: obj[3], close: obj[4], volume: obj[5],
            // })
            arr2.push({
              time: new Date(obj[0]).getTime() / 1000,
              open: obj[1],
              high: obj[2],
              low: obj[3],
              close: obj[4],
            });
          });

          setTradeData(arr2);
          // { time: '2024-09-01', open: 100, high: obj[2], low: obj[3], close: obj[4] },
          // { time: '2024-09-02', open: 105, high: 115, low: 95, close: 110 },
          // { time: '2024-09-03', open: 110, high: 120, low: 100, close: 115 },
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        setGraphDataLoader(false);
      });
  };

  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);

  const updateTradeData = (data1) => {
    if (!data1?.profitTarget) {
      toast.error("Please enter a profit target");
      return;
    } else if (!data1?.stopLoss) {
      toast.error("Please enter a stop loss");
      return;
    }
    if (data1?.profitTarget) {
      if (data?.stopLoss) {
        // data1.realizedRMultiple = calculatePlannedRiskRewardRatio(
        //   data1?.profitTarget,
        //   data?.stopLoss
        // );
        data1.expectedRiskRewardRatio = calculateExpectedRiskRewardRatio(
          data?.side === "SHORT" ? data?.sellPrice : data?.buyPrice,
          data?.stopLoss,
          data1?.profitTarget,
          data?.side
        );
        data1.realizedRiskRewardRatio = calculateRealizedRiskRewardRatio(
          data?.side === "SHORT" ? data?.sellPrice : data?.buyPrice,
          data?.side !== "SHORT" ? data?.sellPrice : data?.buyPrice,
          data?.stopLoss,
          data?.side
        );
      }

      // setLoader(true);
    } else {
      if (data?.profitTarget) {
        // data1.realizedRMultiple = calculatePlannedRiskRewardRatio(
        //   data?.profitTarget,
        //   data1?.stopLoss
        // );
        data1.expectedRiskRewardRatio = calculateExpectedRiskRewardRatio(
          data?.side === "SHORT" ? data?.sellPrice : data?.buyPrice,
          data1?.stopLoss,
          data?.profitTarget,
          data?.side
        );
      }
      // data1.plannedRMultiple = calculateRealizedRMultiple(
      //   data?.side === "SHORT" ? data?.sellPrice : data?.buyPrice,
      //   data?.side !== "SHORT" ? data?.buyPrice : data?.sellPrice,
      //   data1?.stopLoss
      // );
      // data1.expectedRiskRewardRatio = calculateExpectedRiskRewardRatio(
      //   data?.side === "SHORT" ? data?.sellPrice : data?.buyPrice,
      //   data1?.stopLoss ? data1?.stopLoss : data?.stopLoss,
      //   data1?.profitTarget ? data1?.profitTarget : data?.profitTarget,
      //   data?.side
      // );
      data1.realizedRiskRewardRatio = calculateRealizedRiskRewardRatio(
        data?.side === "SHORT" ? data?.sellPrice : data?.buyPrice,
        data?.side !== "SHORT" ? data?.sellPrice : data?.buyPrice,
        data1?.stopLoss,
        data?.side
      );
    }
    setLoader2(true);
    updateTrade(selectedTrade?._id, data1).then((res) => {
      getTradeData();
      setLoader(false);
      setLoader2(false);
    });
  };

  const updateTradeRating = (data) => {
    updateTrade(selectedTrade?._id, data).then((res) => {
      if (res?.data) setData(res?.data);
    });
  };

  /* CONFIDENCE SLIDER */
  useEffect(() => {
    const rangeSlider = document.querySelector("#rangeSlider");
    rangeSlider.addEventListener("change", () => {
      const v = rangeSlider.value;
      updateTradeRating({ rating: Math.round(v * 10) });
      if (v < 0.25) {
        rangeSlider.emoji = "🙁";
      } else if (v < 0.5) {
        rangeSlider.emoji = "🙂";
      } else if (v < 0.75) {
        rangeSlider.emoji = "😄";
      } else {
        rangeSlider.emoji = "🤩";
      }
    });
  }, []);

  /* USE EFFECT */
  useEffect(() => {
    getTradeData();
    getTagData();
    getNote();
  }, [selectedTrade]);

  /* USE EFFECT */
  useEffect(() => {
    if (tagData?.length > 0 && noteData?.tag) {
      let arr = tagData?.filter((obj) => obj?.value === noteData?.tag);
      if (arr && arr?.length > 0) {
        setTag(arr[0]);
      }
    }
  }, [tagData, noteData]);

  /* USE EFFECT */
  useEffect(() => {
    if (mistakeData?.length > 0 && noteData?.tag) {
      let arr = mistakeData?.filter(
        (obj) => obj?.value === noteData?.mistakeTag
      );
      if (arr && arr?.length > 0) {
        setMistake(arr[0]);
      }
    }
  }, [mistakeData, noteData]);

  /* GET FOLDER FUNCTION */
  const getNote = () => {
    let params = { page: 1, sizePerPage: 100 };
    if (selectedTrade) {
      params.trade = selectedTrade?._id;
    }
    setLoading(true);
    getNotebook(params)
      .then((res) => {
        setLoading(false);
        if (res?.data?.docs?.length !== 0) {
          setNoteData(res?.data?.docs[0]);
          if (res?.data?.docs[0]?.text) {
            setQuillValue(res?.data?.docs[0]?.text);
          }
        } else {
          setNoteData();
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };

  /* GET TAG DATA FUNCTION */
  const getTagData = () => {
    getTag()
      .then((res) => {
        if (res?.status) {
          let arr = [];
          let arr2 = [];
          res?.data?.map((obj) => {
            if (obj?.type === "MISTAKE") {
              arr2.push({ value: obj?._id, label: obj?.name, type: obj?.type });
            } else {
              arr.push({
                value: obj?._id,
                label: obj?.name,
                type: obj?.type,
                color: obj?.color,
              });
            }
          });
          setTagData(arr);
          setMistakeData(arr2);
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };

  /* ADD NOTEBOOK FUNCTION */
  const addNote = (data, e, tags) => {
    if (!noteData?._id) {
      let body = {
        tag: tags === "tag" && e ? e?.value : tag?.value,
        type: data?.type,
        title: selectedTrade?.tickerName,
        templete: "100 trades",
        trade: selectedTrade?._id,
        mistakeTag: tags === "mistake" && e ? e?.value : mistake?.value,
      };
      if (quillValue) {
        body.text = quillValue;
      }
      addNotebook(body)
        .then((res) => {
          if (res.status) {
            setQuillValue("");
            setNoteDialog(false);
            getNote();
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          console.log("Error: ", e);
          toast.error(e?.message);
        });
    } else {
      let body = {};
      if (quillValue) {
        body.text = quillValue;
      }
      if (tags === "tag" && e) {
        body.tag = e.value;
      } else if (e) {
        body.mistakeTag = e.value;
      }
      updateNotebook(noteData?._id, body)
        .then((res) => {
          if (res.status) {
            toast.success("The details have been saved successfully.");

            setQuillValue("");
            setNoteDialog(false);
            getNote();
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          console.log("Error: ", e);
          toast.error(e?.message);
        });
    }
  };
  function isFirstDigitZeroNotAcceptable(number) {
    // Convert number to string
    let numberString = number.toString();

    // Check if number is greater than 10 and first digit is zero
    if (number == 0) {
      return number; // First digit is zero and number is greater than 10
    } else if (number > 0 && numberString.charAt(0) !== "0") {
      return number; // First digit is zero and number is greater than 10
    } else {
      return numberString.split("0")[1]; // First digit is not zero or number is less than or equal to 10
    }
  }
  const [closePrice, setClosePrice] = useState();
  const [dateTime, setDateTime] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({ closed: false, dateTime: false });

  const handleSquareOff = () => {
    setIsSubmitted(true);
    let error = errors;
    if (!closePrice) {
      // setErrors({ ...errors, closed: true })
      return;
    } else {
      // setErrors({ ...errors, closed: false })
    }
    if (!dateTime) {
      // setErrors({ ...errors, dateTime: true })
      return;
    } else {
      // setErrors({ ...errors, dateTime: false })
    }
    let body = {
      dateTime: moment(dateTime).format("DD/MM/YYYY HH:MM:SS"),
      price: closePrice.toString(),
    };
    setLoader(true);
    closeTrade(selectedTrade?._id, body).then((res) => {
      if (res?.status) {
        setLoader(false);
        setIsSubmitted(false);
        toast.success(res.message);
        setErrors({ closed: false, dateTime: false });
        setSquareOffDialog(false);
        setClosePrice();
        setDateTime();
        getTradeData();
      }
    });
  };
  const handleSelect = (e) => {
    setDateTime(e);
  };
  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };
  return (
    <section className="p-3 xl:p-5 lg:p-5">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Trade Replay | Trade Talez</title>
      </Helmet>

      <div className="container-full-width">
        <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-y-5 xl:gap-5 lg:gap-5">
          <div className="col-span-2 xl:col-span-1 lg:col-span-1 max-h-[87vh] overflow-x-hidden overflow-y-auto hide-scrollbar">
            {/* NET PROFIT AND LOSS */}
            <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
              <CardBody className="p-0">
                <div className="p-5">
                  <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                    Net P&L (₹)
                  </h2>

                  {loading ? (
                    <>
                      <div className="block dark:hidden">
                        <Skeleton
                          baseColor="#E5E5E5"
                          highlightColor="#F5F5F5"
                          className="mt-3 w-1/2 h-8"
                        />
                      </div>
                      <div className="hidden dark:block">
                        <Skeleton
                          baseColor="#141418"
                          highlightColor="#232327"
                          className="mt-3 w-1/2 h-8"
                        />
                      </div>
                    </>
                  ) : (
                    <h2
                      className={`text-3xl font-proxima-bold ${
                        data?.profit < 0 ? "text-red-500" : "text-green-500"
                      } leading-none mt-3`}
                    >
                      {isNaN(data?.profit)
                        ? "0.00"
                        : parseFloat(data?.profit)?.toLocaleString("en-IN")}
                      {loading && "Loading ..."}
                    </h2>
                  )}
                </div>
              </CardBody>
            </Card>

            {/* CONFIDENCE SLIDER */}
            <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg mt-5 hidden">
              <CardBody className="p-0">
                <div className="p-5">
                  <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                    Confidence
                  </h2>
                  <div className="mt-3">
                    <emoji-slider
                      id="rangeSlider"
                      emoji="🙁"
                      className="rangeSlider"
                      stopPoints={[1, 2, 3, 4, 5]}
                    ></emoji-slider>
                  </div>
                </div>
              </CardBody>
            </Card>

            {/* SETUPS AND MISTAKES */}
            <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg mt-5">
              <CardBody className="p-5">
                <div>
                  <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                    Setups and Mistakes
                  </h2>
                  <p className="text-sm font-proxima-regular dark:text-lightGreyText">
                    Assign the setup and mistakes to this trade
                  </p>
                </div>
                <div className="mt-3">
                  <p className="text-sm font-proxima-regular text-[#777] dark:text-lightGreyText">
                    Setups
                  </p>
                  <div className="block dark:hidden">
                    <Select
                      options={tagData}
                      className="tags-select w-full font-proxima-regular mt-1"
                      classNamePrefix="note-tag"
                      placeholder="Setups"
                      onChange={(e) => {
                        setTag(e);
                        addNote({ type: "TRADE" }, e, "tag");
                      }}
                      value={tag}
                    />
                  </div>
                  <div className="hidden dark:block">
                    <Select
                      options={tagData}
                      className="tags-select-dark w-full font-proxima-regular mt-1"
                      classNamePrefix="note-tag"
                      placeholder="Setups"
                      onChange={(e) => {
                        setTag(e);
                        addNote({ type: "TRADE" }, e, "tag");
                      }}
                      value={tag}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <p className="text-sm font-proxima-regular text-[#777] dark:text-lightGreyText">
                    Mistakes
                  </p>
                  <div className="block dark:hidden">
                    <Select
                      options={mistakeData}
                      className="tags-select w-full font-proxima-regular mt-1"
                      classNamePrefix="note-tag"
                      placeholder="Mistakes"
                      onChange={(e) => {
                        setMistake(e);
                        addNote({ type: "TRADE" }, e, "mistake");
                      }}
                      value={mistake}
                    />
                  </div>
                  <div className="hidden dark:block">
                    <Select
                      options={mistakeData}
                      className="tags-select-dark w-full font-proxima-regular mt-1"
                      classNamePrefix="note-tag"
                      placeholder="Mistakes"
                      onChange={(e) => {
                        setMistake(e);
                        addNote({ type: "TRADE" }, e, "mistake");
                      }}
                      value={mistake}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>

            {/* PROFIT TARGET AND STOP LOSS */}
            <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg mt-5">
              <CardBody className="p-5">
                <div className="flex items-center justify-between">
                  <div>
                    <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                      Profit Target and Stop Loss
                    </h2>
                    <p className="text-sm font-proxima-regular dark:text-lightGreyText">
                      Allocate profit target and stop loss
                    </p>
                  </div>
                  <Button
                    className="bg-logoOcre hover:bg-opacity-80 text-sm font-proxima-semibold normal-case shadow-none hover:shadow-none py-1 px-3 rounded"
                    onClick={() => {
                      updateTradeData({
                        stopLoss: stopLoss,
                        profitTarget: profit,
                      });
                    }}
                    disabled={loader2}
                  >
                    {loader2 ? "Saving..." : "Save"}
                  </Button>
                </div>

                <div className="mt-3">
                  <div className="form-group">
                    <p className="text-sm font-proxima-regular text-[#777] dark:text-lightGreyText">
                      Profit Target
                    </p>
                    <div className="border border-[#E5E5E5] dark:border-darkBorder rounded-md px-2 pl-3 py-[0.8vh] flex items-center gap-2 mt-1">
                      <div className="w-4/5">
                        <input
                          type="number"
                          onChange={(e) => {
                            setProfit(e.target.value);
                          }}
                          onKeyDown={handleKeyDown}
                          onWheel={(e) => e.target.blur()}
                          value={profit}
                          placeholder="Profit Target"
                          className="w-full block text-sm font-proxima-semibold text-black dark:text-white border border-transparent bg-transparent placeholder:text-gray-500 placeholder:font-proxima-regular outline-none focus:outline-none"
                        />
                      </div>
                      {/* <div className="w-1/5">
                        <Button
                          className="w-full bg-logoOcre hover:bg-opacity-80 text-sm font-proxima-semibold normal-case shadow-none hover:shadow-none py-1 px-3 rounded"
                          onClick={() => {
                            updateTradeData({ profitTarget: profit });
                          }}
                          disabled={loader}
                        >
                          {loader ? "Saving..." : "Save"}
                        </Button>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="form-group">
                    <p className="text-sm font-proxima-regular text-[#777] dark:text-lightGreyText">
                      Stop Loss
                    </p>
                    <div className="border border-[#E5E5E5] dark:border-darkBorder rounded-md px-2 pl-3 py-[0.8vh] flex items-center gap-2 mt-1">
                      <div className="w-4/5">
                        <input
                          type="number"
                          onChange={(e) => {
                            setStoploss(e.target.value);
                          }}
                          onKeyDown={handleKeyDown}
                          onWheel={(e) => e.target.blur()}
                          value={stopLoss}
                          placeholder="Stop Loss"
                          className="w-full block text-sm font-proxima-semibold text-black dark:text-white border border-transparent bg-transparent placeholder:text-gray-500 placeholder:font-proxima-regular outline-none focus:outline-none"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>

            {/* TRADE DETAILS */}
            <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg mt-5">
              <CardBody className="p-5">
                <div>
                  <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                    Position Details
                  </h2>
                  <p className="text-sm font-proxima-regular dark:text-lightGreyText">
                    All the other details about this trade
                  </p>
                </div>
                <div className="mt-5">
                  {statsVariables.map((stat) => (
                    <React.Fragment key={stat.id}>
                      <StatisticsTile title={stat.title} value={stat.value} />
                    </React.Fragment>
                  ))}
                </div>
              </CardBody>
            </Card>

            {/* ATTACHMENTS */}
            <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg mt-5 shadow-none">
              <CardBody className="p-5">
                <h2 className="text-base font-proxima-semibold text-black dark:text-white">
                  Attachments
                </h2>
                <div className="mt-3">
                  {/* ATTACHMENT BOX */}
                  <div className="relative">
                    <label htmlFor="attachment" className="block">
                      <div className="border border-[#E5E5E5] dark:border-darkBorder w-full rounded-md p-3 flex items-center gap-3 cursor-pointer">
                        <FaFileUpload className="w-9 h-9 text-lightGreyText" />
                        <div>
                          <h5 className="text-base font-proxima-semibold text-black dark:text-white">
                            Upload attachments
                          </h5>
                          <p className="text-sm font-proxima-regular text-greyText">
                            Click here to upload file from your computer
                          </p>
                        </div>
                      </div>
                    </label>
                    <input
                      type="file"
                      id="attachment"
                      onChange={(e) => {
                        let formData = new FormData();
                        formData.append("file", e.target.files[0]);
                        uploadAttByTrade(data?._id, formData).then((res) => {
                          if (res?.status) {
                            toast.success("Attachment uploaded successfully");
                          }
                          getTradeData();
                        });
                      }}
                      className="absolute w-full h-full top-0 left-0 invisible"
                    />
                  </div>

                  {/* FILES LIST */}
                  {data?.files &&
                    data?.files?.map((file) => {
                      const splitUrl = file.toString().split("/");
                      const splitName = splitUrl[4];
                      const fileName = splitName.replace(/\d+/g, "");

                      return (
                        <a
                          href={file}
                          target="_blank"
                          rel="noreferrer"
                          className="w-full bg-white dark:bg-bgDark hover:bg-[#F5F5F5] border border-[#E5E5E5] dark:border-darkBorder rounded-md px-3 py-2 flex items-center gap-3 mt-3 cursor-pointer"
                        >
                          <div className="w-10 h-10 bg-[#F5F5F5] dark:bg-darkSidebar rounded flex items-center justify-center flex-shrink-0">
                            <FaFile className="w-5 h-5 text-lightGreyText" />
                          </div>
                          <div>
                            <p
                              className="text-sm font-proxima-semibold text-black dark:text-white whitespace-normal"
                              style={{ overflowWrap: "anywhere" }}
                            >
                              {fileName}
                            </p>
                            <p className="text-[1.3vh] font-proxima-regular text-lightGreyText">
                              Click to view the file
                            </p>
                          </div>
                        </a>
                      );
                    })}
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="col-span-3 h-fit relative xl:sticky lg:sticky top-0 xl:top-5 lg:top-5">
            {/* TICKER DETAILS */}
            <Card className="bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg">
              <CardBody className="p-5">
                <div className="block xl:flex lg:flex items-center justify-between">
                  <div>
                    <h2 className="text-lg font-proxima-bold text-black dark:text-white">
                      {selectedTrade?.tickerName}
                    </h2>
                    <p className="text-sm font-proxima-semibold text-black dark:text-white text-opacity-60 dark:text-opacity-60">
                      {data?.date
                        ? moment(
                            moment(
                              data?.date?.split("/")[1] +
                                "/" +
                                data?.date?.split("/")[0] +
                                "/" +
                                data?.date?.split("/")[2]
                            ),
                            data?.time
                          ).format("dddd, MMM DD YYYY")
                        : ""}
                    </p>
                  </div>
                  <div className="flex items-center gap-3 mt-3 xl:mt-0 lg:mt-0">
                    {data?.isOpen && (
                      <Button
                        className="w-full xl:w-fit lg:w-fit text-center shadow-none hover:shadow-none bg-logoOcre bg-opacity-20 rounded-md text-base font-proxima-semibold text-logoOcre gap-2 py-[1vh] normal-case"
                        onClick={() => setSquareOffDialog(!squareOffDialog)}
                      >
                        Square off
                      </Button>
                    )}
                    <Button
                      className="w-full xl:w-fit lg:w-fit text-center shadow-none hover:shadow-none bg-logoOcre rounded-md text-base font-proxima-semibold text-white gap-2 py-[1vh] normal-case"
                      onClick={() => setNoteDialog(!noteDialog)}
                    >
                      {noteData?._id ? "Update " : "Add "} note
                    </Button>
                  </div>
                </div>
                <div className="w-full bg-[#F5F5F5] dark:bg-bgDark bg-opacity-30 border border-[#E5E5E5] dark:border-darkBorder rounded-lg px-5 py-3 mt-5 cursor-pointer">
                  <div className="block dark:hidden">
                    <ReactQuill
                      modules={{
                        toolbar: false,
                      }}
                      theme="snow"
                      toolbar={false}
                      className="rounded-lg text-base dark:text-white text-black readonly-editor font-proxima-regular trade-editor def-cursor"
                      placeholder="The note for this trade will be displayed here"
                      readOnly
                      value={noteData?.text}
                    />
                  </div>
                  <div className="hidden dark:block">
                    <ReactQuill
                      modules={{
                        toolbar: false,
                      }}
                      theme="snow"
                      toolbar={false}
                      className="rounded-lg text-base dark:text-white text-black readonly-editor font-proxima-regular trade-editor dark def-cursor"
                      placeholder="The note for this trade will be displayed here"
                      readOnly
                      value={noteData?.text}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>

            {/* TRADING VIEW CHART */}
            {/* use this key for loader graphDataLoader */}
            <Card className="block bg-white dark:bg-darkSidebar border border-[#E5E5E5] dark:border-darkBorder rounded-lg h-auto xl:h-[68vh] lg:h-[68vh] mt-5">
              {selectedTrade?.tickerName && (
                <CardBody
                  className={`p-2 h-full ${
                    (!graphDataLoader && tradeData?.length) === 0
                      ? "flex items-center justify-center"
                    : "flex items-center justify-center"
                  }`}
                >
                  <div className="w-full xl:w-1/3 lg:w-1/3 mx-auto">
                    <IconMoodSpark className="w-16 h-16 mx-auto text-black dark:text-white" />
                    <div className="mt-3">
                      <h5 className="text-xl font-proxima-semibold text-black dark:text-white text-center">
                        Coming Soon!
                      </h5>
                      <p className="mt-1 font-proxima-regular text-base text-greyText dark:text-sidebarText text-center">
                     We are in the process of integrating TradingView charts for a more interactive and real-time market analysis experience. Stay tuned as we work on bringing this feature to you!    </p>
                    </div>
                  </div>
                  {/* {graphDataLoader ? (
                    <div className="p-3">
                      <div className="block dark:hidden">
                        <Skeleton
                          baseColor="#E5E5E5"
                          highlightColor="#F5F5F5"
                          className="w-1/2 h-6"
                        />
                      </div>
                      <div className="hidden dark:block">
                        <Skeleton
                          baseColor="#141418"
                          highlightColor="#232327"
                          className="w-1/2 h-6"
                        />
                      </div>

                      <div className="mt-3 block dark:hidden">
                        <Skeleton
                          baseColor="#E5E5E5"
                          highlightColor="#F5F5F5"
                          className="w-full h-[35vh] xl:h-[58vh] lg:h-[58vh]"
                        />
                      </div>
                      <div className="mt-3 hidden dark:block">
                        <Skeleton
                          baseColor="#141418"
                          highlightColor="#232327"
                          className="w-full h-[35vh] xl:h-[58vh] lg:h-[58vh]"
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      {tradeData?.length === 0 ? (
                        <div className="w-full xl:w-1/3 lg:w-1/3 mx-auto">
                          <IconMoodSadFilled className="w-16 h-16 mx-auto text-black dark:text-white" />
                          <div className="mt-3">
                            <h5 className="text-xl font-proxima-semibold text-black dark:text-white text-center">
                              No data found for this trade
                            </h5>
                            <p className="mt-1 font-proxima-regular text-base text-greyText dark:text-sidebarText text-center">
                              Oops! We were unable to retrieve the data for this
                              ticker at the moment.
                            </p>
                          </div>
                        </div>
                      ) : (
                        <>
                          {tradeData?.length > 0 && (
                            <div className="block dark:hidden h-full">
                              <NewTradingViewLight
                                data={tradeData}
                                ticker={selectedTrade?.tickerName}
                              />
                            </div>
                          )}
                          {tradeData?.length > 0 && (
                            <div className="hidden dark:block h-full">
                              <NewTradingViewDark
                                data={tradeData}
                                ticker={selectedTrade?.tickerName}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )} */}
                </CardBody>
              )}
            </Card>
          </div>
        </div>
      </div>

      {/* NOTES DIALOG */}
      <Dialog
        open={noteDialog}
        handler={() => setNoteDialog(!noteDialog)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="dark:bg-darkSidebar rounded-lg"
      >
        <DialogBody className="p-0">
          <div className="px-5 py-3 border-b border-[#E5E5E5] dark:border-darkBorder">
            <h5 className="text-base font-proxima-bold text-black dark:text-white">
              {" "}
              {noteData?._id ? "Update " : "Add "} note
            </h5>
          </div>
          <div className="p-0">
            <div className="block dark:hidden">
              <ReactQuill
                theme="snow"
                value={quillValue}
                onChange={setQuillValue}
                className="modal-box rounded-lg text-base"
                placeholder="Add your note here"
                preserveWhitespace={true}
              />
            </div>
            <div className="hidden dark:block">
              <ReactQuill
                theme="snow"
                value={quillValue}
                onChange={setQuillValue}
                className="modal-box rounded-lg text-base dark"
                placeholder="Add your note here"
                preserveWhitespace={true}
              />
            </div>
          </div>
          <div className="px-5 py-3 border-t border-[#E5E5E5] dark:border-darkBorder flex items-center justify-end gap-2">
            <Button
              className="text-sm font-proxima-semibold text-black bg-[#F5F5F5] hover:bg-opacity-75 border border-[#E5E5E5] dark:border-darkBorder shadow-none hover:shadow-none normal-case py-2 rounded-md"
              onClick={() => setNoteDialog(!noteDialog)}
            >
              Cancel
            </Button>
            <Button
              onClick={() => addNote({ type: "TRADE" })}
              className="text-sm font-proxima-semibold text-white bg-buttonColor hover:bg-opacity-80 border border-buttonColor shadow-none hover:shadow-none normal-case py-2 rounded-md"
            >
              {noteData?._id ? "Update " : "Save "}
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* SQUARE OFF DIALOG */}
      <Dialog
        open={squareOffDialog}
        handler={() => setSquareOffDialog(!squareOffDialog)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="dark:bg-darkSidebar rounded-lg"
      >
        <DialogBody className="p-0">
          <div className="px-5 py-3 border-b border-[#E5E5E5] dark:border-darkBorder">
            <h5 className="text-base font-proxima-bold text-black dark:text-white">
              Square off this trade
            </h5>
          </div>
          <div className="p-5">
            <div className="form-group">
              <label className="text-sm font-proxima-semibold text-black dark:text-white">
                Price <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <input
                  value={closePrice}
                  onChange={(e) =>
                    setClosePrice(isFirstDigitZeroNotAcceptable(e.target.value))
                  }
                  type="number"
                  min={0}
                  placeholder="Price"
                  className="w-full px-5 py-2 rounded-md block bg-transparent border border-borderGrey dark:border-darkBorder text-sm font-proxima-semibold text-black dark:text-white placeholder:text-gray-500 placeholder:font-proxima-regular outline-none focus:outline-none"
                />
                {isSubmitted && !closePrice && "Please enter the price."}
              </div>
            </div>
            <div className="form-group mt-5">
              <label className="text-sm font-proxima-semibold text-black dark:text-white">
                Date and Time <span className="text-red-500">*</span>
              </label>
              <div className="border border-borderGrey dark:border-darkBorder py-2 px-3 rounded-lg mt-1">
                <div className="mt-1">
                  <div className="block dark:hidden">
                    <DateTime
                      onChange={handleSelect}
                      value={dateTime}
                      isValidDate={validation}
                      className="border-none w-full text-sm font-proxima-semibold text-black dark:text-white bg-transparent new-datepicker"
                    />
                  </div>
                  <div className="hidden dark:block">
                    <DateTime
                      onChange={handleSelect}
                      value={dateTime}
                      isValidDate={validation}
                      className="border-none w-full text-sm font-proxima-semibold text-black dark:text-white bg-transparent new-datepicker dark"
                    />
                  </div>
                </div>
              </div>
              {isSubmitted && !dateTime && "Please enter date and time."}
            </div>
          </div>
          <div className="px-5 py-3 border-t border-[#E5E5E5] dark:border-darkBorder flex items-center justify-end gap-2">
            <Button
              className="text-sm font-proxima-semibold text-black bg-[#F5F5F5] hover:bg-opacity-75 border border-[#E5E5E5] dark:border-darkBorder shadow-none hover:shadow-none normal-case py-2 rounded-md"
              onClick={() => setSquareOffDialog(!squareOffDialog)}
            >
              Cancel
            </Button>
            <Button
              disabled={loader}
              onClick={handleSquareOff}
              className="text-sm font-proxima-semibold text-white bg-buttonColor hover:bg-opacity-80 border border-buttonColor shadow-none hover:shadow-none normal-case py-2 rounded-md"
            >
              {loader ? "Loading..." : "Square off"}
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    </section>
  );
};

export default TradeReplay;
